.nota-container {
	color: var(--dark-black);
	letter-spacing: 0;
	text-align: left;
	width: 100%;
}

.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.nota-container-text {
	margin-bottom: 0rem;
	width: 100%;
	position: relative;
	padding: 0 0.5rem;
}

.nota-title {
	font-size: 2rem;
    padding: 0.25rem 1rem 0.75rem 1rem;
    font-weight: 400;
    margin: 0;
    line-height: 1.15;
	width: 100%;
	font-weight: 500;
}

.nota-summary {
	display: none;
	font-size: 1rem;
	line-height: 1.375;
	color: var(--gray-text);
}
.background{
	background: #fff000;
	padding: 20px 10px;
}
.nota-autor {
	margin-top: 0rem;
	color: var(--medium-black);
    font-weight: 300;
    font-size: .8rem;
	text-align: center;
	margin-bottom: 0.7rem;
}
.nota-autor a{
	font-weight: 700;
	text-align: center;
}
.display-none {
	display: none;
}
.blue-txt { 
	color: var(--sky-blue);
	font-size: 1.25rem;
	margin-right: 5px;
	
}
.nota-container-text h2 {
	margin:0;
	width: 100%;
	line-height: 1;
	text-align: center;
	font-family: Georgia, 'Times New Roman', Times, serif;
}

.img-container {
	margin-bottom: 5px;
	overflow: hidden;
	}

.img-opinion {
	height: 74px;
	margin-bottom: 5px;
	width: 74px;
    overflow: hidden;
	border-radius: 50px;
	margin-top: -3rem;
	margin-left: auto;
	margin-right: auto;
	border: 3px solid var(--light-gray);
	
	}


.grid-item {
	border: 1px solid var(--gray-text);
	border-radius: 3px;
}	


@media (max-width: 768px) {
	.img-container{
		/* min-height: 0px; */
		width: 100%;
		height: auto;
		
	}
	.nota-container-text {
		margin-bottom: 0rem;
		margin-top: 1rem;
	}
	h2 .nota-title {
	font-size: 1.25rem;
    padding: 0.25rem 0 0.75rem 0;
    font-weight: 500;
    margin: 0;
    line-height: 1.20;
	}
	.nota-summary {
		margin: 0rem 0;
	}
	.nota-autor {
		margin-top: 0.6rem;
	}
	
}